import Vue from "vue";
import {
  LayoutPlugin,
  SpinnerPlugin,
  BootstrapVue,
  IconsPlugin,
  FormPlugin,
} from "bootstrap-vue";
import App from "./App.vue";
import router from "./router/index";

import loader from "vue-ui-preloader";
import Viewer from "v-viewer";
import VideoBackground from "vue-responsive-video-background-player";
import { Plugin } from "vue-responsive-video-background-player";

Vue.use(Plugin);
Vue.component("video-background", VideoBackground);

Vue.use(Viewer);
Vue.use(loader);
Vue.use(LayoutPlugin);
Vue.use(SpinnerPlugin);
Vue.use(BootstrapVue);
Vue.use(FormPlugin);
Vue.use(IconsPlugin);

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#tattoo-brest");
