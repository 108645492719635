import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "MainView",
    component: () => import("@/views/MainView.vue"),
    children: [
      {
        path: "",
        name: "main.home",
        component: () => import("@/pages/home-page/HomePage.vue"),
      },
      {
        path: "/*",
        name: "main.error",
        component: () => import("@/pages/404-page/404.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: "/",
  routes,
});

export default router;
