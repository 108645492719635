<template>
  <div id="app">
    <loader
      v-if="loading"
      object="#ff00b7"
      color1="#000"
      color2="#17fd3d"
      size="10"
      speed="2"
      bg="#000"
      objectbg="#fff"
      opacity="0"
      disableScrolling="true"
      name="spinning"
    ></loader>
    <router-view />
  </div>
</template>
<script>
export default {
  data() {
    return {
      loading: true,
    };
  },
  mounted() {
    document.onreadystatechange = () => {
      if (document.readyState === "complete") {
        this.loading = false;
      } else this.loading = true;
    };
  },
};
</script>
